import React, { useEffect, useState } from "react";
import api from "../../api/axios";

const MargeNetteTable = () => {
    const [chains, setChains] = useState([]);
    const [selectedChain, setSelectedChain] = useState("");
    const [model, setModel] = useState(null);
    const [models, setModels] = useState([]);
    const [systemConstant, setSystemConstant] = useState({
        Nombre_d_heures_par_jour: "",
        Taux_horaire_SMIG_16_29: "",
        Taux_horaire_17_00: "",
        Taux_horaire_17_50: "",
        Masse_salariale_16_29: "",
        Masse_salariale_17_00: "",
        Masse_salariale_17_50: "",
        Capacité_par_unité_transport: "",
        cotisation_entroprise_trans: "",
        Coût_par_trajet: "",
        Coût_énergie_journalier: "",
        Coût_charges_fixes_journalier: "",
    });
     const [totalHours, setTotalHours] = useState(0);
    const [selectedChainSortie, setSelectedChainSortie] = useState(0)
    const [totalSortie , setTotalSortie] = useState(0)
    const [fils, setFils] = useState(0);
    const [plastique, setPlastique] = useState(0);
    const [coûtEnergie, setCoûtEnergie] = useState(0);
    const [coûtChargesFixes, setCoûtChargesFixes] = useState(0);
    const [totalMassSalary, setTotalMassSalary] = useState(0);
    const [couteTransport, setCouteTransport] = useState(0);
    const [selectedModel , setSelectedModel] = useState(0);
    const [coûtDeRevient, setCoûtDeRevient] = useState(0);
    const [effectifDirect, setEffectifDirect] = useState(0);
    const [effectifInDirect, setEffectifInDirect] = useState(0);
    const [totalChains, setTotalChains] = useState(0);
    const [totalExport, setTotalExport] = useState(0);
    const [error , setError] = useState('');
    const [productionDepotValeur , setProductionDepotValeur] = useState(null)

    useEffect(() => {
        const fetchChains = async () => {
            try {
                const response = await api.get("/chains");
                setChains(response.data);
            } catch (error) {
                console.error("Error fetching chains:", error);
            }
        };

        fetchChains();
    }, []);

    useEffect(() => {
        const fetchModelByChain = async () => {
            if (selectedChain) {
                try {
                    const response = await api.get(`/getmodelsByChain/${selectedChain}`);
                    fetchChainData(response.data.id)
                    setSelectedModel(response.data.id)
                    const responsed = await api.get(`/chain_production_data/${response.data.id}?chain=${selectedChain}`);
                    const allResponse = await api.get(`/chain_production_data/${response.data.id}`);
                    const alldata = allResponse.data;
                    // Initialize totals
                    let totalSortie = 0;

                    // Calculate totals by iterating through keys
                    Object.keys(alldata).forEach((key) => {
                        const chainData = alldata[key];
                        totalSortie += chainData.latestSortie || 0;
                    });
                    setTotalSortie(totalSortie)

                    const chainData = responsed.data[selectedChain];
                    const sortie = chainData.latestSortie;
                    setSelectedChainSortie(sortie);
                    setModel(response.data);
                } catch (error) {
                    console.error("Error fetching model by chain:", error);
                    setModel(null);
                }
            }
        };

        fetchModelByChain();
    }, [selectedChain]);

    const fetchWorkedHours = async (modelId) => {
        try {
          const response = await api.get(`/product_plans_worked_hours/${modelId}`);
          setTotalHours(response.data.worked_hours_count);
        } catch (error) {
          console.error("Error fetching worked hours:", error);
          setError(error.response?.data?.message || "An error occurred");
        }
      };
    
      const fetchChainData = async (modelId) => {
        try {
          setError(null);
            const response = await api.get(`/export/${modelId}`);
            const exportData = response.data;
            setTotalExport(exportData.total);
      
            const modelData = models.find((model) => {
              return model.id == modelId;
            });
      
            const productionDepotValeur = (
              modelData.prixMOver *
              (modelData.prixFacture /
                (modelData.prixMOver * modelData.qte_societe)) *
              exportData.total
            ).toFixed(2);
      
            setProductionDepotValeur(productionDepotValeur);
        } catch (error) {
          console.error(error);
          setError(error.response?.data?.message || "An error occurred");
        }
      };
    
    useEffect(() => {
        const fetchData = async () => {
          try {
            const modelsResponse = await api.get("/models");
            setModels(modelsResponse.data);
    
            const systemResponse = await api.get("/system_constants_latest");
            if (systemResponse.data) {
              const { id, created_at, updated_at, effectif_fix, ...filteredData } =
                systemResponse.data;
              setSystemConstant({
                ...filteredData,
              });
            }
    
            const responseIndirect = await api.get(`/effective_indirect`);
            const responseChains = await api.get(`/total_chain`);
            if(selectedModel) {
              const responseDirect = await api.get(
                `/effective_direct_real/${selectedModel}`
              );
              setEffectifDirect(parseInt(responseDirect.data.total_effectif_direct));
            }
    
            setEffectifInDirect(
              parseInt(responseIndirect.data.total_effectif_indirect)
            );
            setTotalChains(parseInt(responseChains.data.total_chains));
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
        fetchData();
      }, [selectedModel]);
    

    const handleSelectChange = (e) => {
        setSelectedChain(e.target.value);
    };

    const getChainName = (chainId) => {
        const chain = chains.find((chain) => chain.id == chainId);
        return chain ? chain.name : "";
    };

    const calculateCouteTransport = () => {
        const QP_effectif = effectifInDirect / totalChains;
        const {
          Nombre_d_heures_par_jour,
          Capacité_par_unité_transport,
          Coût_par_trajet,
          cotisation_entroprise_trans,
        } = systemConstant;
    
        const Nombre_de_jours_de_production = totalHours / Nombre_d_heures_par_jour;
    
        const Refacturation_transport_MOD_indirecte = -(
          (cotisation_entroprise_trans *
            Nombre_de_jours_de_production *
            QP_effectif) /
          26
        );
    
        const Refacturation_transport_MOD_directe = -(
          (cotisation_entroprise_trans *
            Nombre_de_jours_de_production *
            effectifDirect) /
          26
        );
    
        const Nombre_unite_utilise =
          (QP_effectif + effectifDirect) / Capacité_par_unité_transport;
    
        const transportCost =
          2 *
            Nombre_unite_utilise *
            Nombre_de_jours_de_production *
            Coût_par_trajet +
          (Refacturation_transport_MOD_directe +
            Refacturation_transport_MOD_indirecte);
    
        setCouteTransport(transportCost.toFixed(4));
      };
    
      const calculateTotalMasseSalariale = () => {
        const {
          Taux_horaire_SMIG_16_29,
          Taux_horaire_17_00,
          Taux_horaire_17_50,
          Masse_salariale_16_29,
          Masse_salariale_17_00,
          Masse_salariale_17_50,
        } = systemConstant;
    
        const congeAnual =
          (totalHours * effectifDirect * Taux_horaire_SMIG_16_29 * 1.5) / 26;
    
        const masseSalariale1629 =
          totalHours *
          effectifDirect *
          Masse_salariale_16_29 *
          0.01 *
          Taux_horaire_SMIG_16_29;
    
        const masseSalariale1700 =
          totalHours *
          effectifDirect *
          Masse_salariale_17_00 *
          0.01 *
          Taux_horaire_17_00;
    
        const masseSalariale1750 =
          totalHours *
          effectifDirect *
          Masse_salariale_17_50 *
          0.01 *
          Taux_horaire_17_50;
    
        const QP_effectif = effectifInDirect / totalChains;
    
        const congeAnualIndirect =
          (totalHours * QP_effectif * Taux_horaire_SMIG_16_29 * 1.5) / 26;
    
        const masseSalarialeIndirect1629 =
          totalHours *
          QP_effectif *
          Masse_salariale_16_29 *
          0.01 *
          Taux_horaire_SMIG_16_29;
    
        const masseSalarialeIndirect1700 =
          totalHours *
          QP_effectif *
          Masse_salariale_17_00 *
          0.01 *
          Taux_horaire_17_00;
    
        const masseSalarialeIndirect1750 =
          totalHours *
          QP_effectif *
          Masse_salariale_17_50 *
          0.01 *
          Taux_horaire_17_50;
    
        const masseSalarialeDirecte =
          masseSalariale1629 + masseSalariale1700 + masseSalariale1750 + congeAnual;
    
        const masseSalarialeInDirecte =
          masseSalarialeIndirect1700 +
          masseSalarialeIndirect1629 +
          masseSalarialeIndirect1750 +
          congeAnualIndirect;
    
        setTotalMassSalary(
          (masseSalarialeInDirecte + masseSalarialeDirecte).toFixed(4)
        );
      };
    
      const calculateCouteFilAndPlastique = () => {
        setFils(totalExport * (20000 / 18602));
        setPlastique(totalExport * (15000 / 18602));
      };
    
      const calculateCoûtEnergie = () => {
        const { Coût_énergie_journalier, Nombre_d_heures_par_jour } =
          systemConstant;
        const Nombre_de_jours_de_production = totalHours / Nombre_d_heures_par_jour;
        setCoûtEnergie(Nombre_de_jours_de_production * Coût_énergie_journalier);
      };
    
      const calculateCoûtChargesFixes = () => {
        const { Coût_charges_fixes_journalier, Nombre_d_heures_par_jour } =
          systemConstant;
        const Nombre_de_jours_de_production = totalHours / Nombre_d_heures_par_jour;
        setCoûtChargesFixes(
          Nombre_de_jours_de_production * Coût_charges_fixes_journalier
        );
      };
    
      const calculateCoûtDeRevient = () => {
        const totalCost =
          parseFloat(fils) +
          parseFloat(plastique) +
          parseFloat(totalMassSalary) +
          parseFloat(couteTransport) +
          parseFloat(coûtChargesFixes) +
          parseFloat(coûtEnergie);
    
        setCoûtDeRevient(totalCost.toFixed(2));
      };

        useEffect(() => {
          if (
            totalHours > 0 &&
            effectifDirect > 0 &&
            effectifInDirect > 0 &&
            totalChains > 0 &&
            systemConstant.Nombre_d_heures_par_jour
          ) {
            calculateCouteTransport();
            calculateTotalMasseSalariale();
            calculateCouteFilAndPlastique();
            calculateCoûtEnergie();
            calculateCoûtChargesFixes();
          }
        }, [
          totalHours,
          effectifDirect,
          effectifInDirect,
          totalChains,
          totalExport,
          systemConstant,
        ]);
      
        useEffect(() => {
          if (selectedModel) {
            fetchWorkedHours(selectedModel);
          }
        }, [selectedModel]);
      
        useEffect(() => {
           calculateCoûtDeRevient();
        },  [
            fils,
            plastique,
            totalMassSalary,
            couteTransport,
            coûtChargesFixes,
            coûtEnergie,
          ])


        return (
        <div className="ml-7 mt-5">
            <div className="flex items-center justify-between">
                <h2 className="text-2xl font-semibold">Marge réelle</h2>
                <div className="ml-7 mb-4 pr-6">
                        <select
                            className="block w-full mt-4 outline-0 p-[.5rem] border border-[#b3b3b3] focus:border-2 focus:border-[#2684ff] rounded"
                            value={selectedChain}
                            onChange={handleSelectChange}
                        >
                            <option value="">Select a Chain</option>
                            {chains.map((chain) => (
                                <option key={chain.id} value={chain.id}>
                                    {chain.name}
                                </option>
                            ))}
                        </select>
                </div>
            </div>   
            {model && (     
            <div className="overflow-x-auto mt-5">
                <table className="table-auto w-full bg-slate-200 border-collapse border border-gray-300 shadow-md">
                   <thead className="bg-gray-100">
                     <tr>
                        <th className="px-6 py-3 min-w-[200px] text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Chaine</th>
                        <th className="px-6 py-3 min-w-[200px] text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Modèle</th>
                        <th className="px-6 py-3 min-w-[200px] text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Client</th>
                        <th className="px-6 py-3 min-w-[200px] text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Prix main d'œuvre</th>
                        <th className="px-6 py-3 min-w-[200px] text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Photo</th>
                        <th className="px-6 py-3 min-w-[200px] text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Production réelle chaine</th>
                        <th className="px-6 py-3 min-w-[200px] text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Production réelle dépôt</th>
                        <th className="px-6 py-3  min-w-[200px] text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Coût de revient réel chaine</th>
                        <th className="px-6 py-3 min-w-[200px] text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Coût de revient réel dépôt final</th>
                        <th className="px-6 py-3 min-w-[200px] text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Marge nette réelle par chaine</th>
                        <th className="px-6 py-3 min-w-[200px] text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Marge nette réelle de l'article en dépôt final</th>
                        <th className="px-6 py-3 min-w-[200px] text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Taux de marge nette réelle par chaine</th>
                        <th className="px-6 py-3 min-w-[200px] text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Taux de marge nette réelle de l'article en dépôt final</th>
                        <th className="px-6 py-3 min-w-[200px] text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Taux de marge nette prévisionnel par chaine</th>
                        <th className="px-6 py-3 min-w-[200px] text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Ecart/taux de marge nette par chaine</th>
                     </tr>
                   </thead>
                   <tbody>
                     <tr>
                        <th className="p-[2rem] min-w-[200px] text-left text-[#4E4A4A] font-semibold border border-gray-300">{getChainName(selectedChain)}</th>
                        <th className="p-[2rem] min-w-[200px] text-left text-[#4E4A4A] font-semibold border border-gray-300">{model.modele}</th>
                        <th className="p-[2rem] min-w-[200px] text-left text-[#4E4A4A] font-semibold border border-gray-300">{model.client}</th>
                        <th className="p-[2rem] min-w-[200px] text-left text-[#4E4A4A] font-semibold border border-gray-300">{model.prixMOver}</th>
                        <th className="p-[2rem] min-w-[200px] text-left text-[#4E4A4A] font-semibold border border-gray-300">
                            <img
                                src={`http://crosscheckconf.net:8000${model.image}`}
                                alt={model.name}
                                className="h-[50px] w-[50px]"
                            />
                        </th>
                        <th className="p-[2rem] min-w-[200px] text-left text-[#4E4A4A] font-semibold border border-gray-300">{selectedChainSortie}</th>
                        <th className="p-[2rem] min-w-[200px] text-left text-[#4E4A4A] font-semibold border border-gray-300">{totalSortie}</th>
                        <th className="p-[2rem] min-w-[200px] text-left text-[#4E4A4A] font-semibold border border-gray-300"></th>
                        <th className="p-[2rem] min-w-[200px] text-left text-[#4E4A4A] font-semibold border border-gray-300">{coûtDeRevient}</th>
                     </tr>
                   </tbody>
                </table>
            </div>
            )}
        </div>
    )
}

export default MargeNetteTable
