import React, { useEffect, useState } from "react";
import api from "../../api/axios";

const SuivrDeEffect = () => {
    const [chains, setChains] = useState([]);
    const [selectedChain, setSelectedChain] = useState("");
    const [model, setModel] = useState(null);
    const [effectiveData, setEffectiveData] = useState({});
    const [effectiveRealData, setEffectiveRealData] = useState({});

    useEffect(() => {
        const fetchChains = async () => {
            try {
                const response = await api.get("/chains");
                setChains(response.data);
            } catch (error) {
                console.error("Error fetching chains:", error);
            }
        };

        fetchChains();
    }, []);

    useEffect(() => {
        const fetchModelByChain = async () => {
            if (selectedChain) {
                try {
                    const response = await api.get(`/getmodelsByChain/${selectedChain}`);
                    setModel(response.data);
                    fetchEffectiveData(response.data.id); // Fetch effective data
                } catch (error) {
                    console.error("Error fetching model by chain:", error);
                    setModel(null);
                }
            }
        };

        fetchModelByChain();
    }, [selectedChain]);

    const handleSelectChange = (e) => {
        setSelectedChain(e.target.value);
        setModel(null);
        setEffectiveData({});
        setEffectiveRealData({});
    };

    const fetchEffectiveData = async (modelId) => {
        try {
            // Fetch standard effective data
            const effectiveResponse = await api.get(`/effective_data_total/${modelId}`);
            const effectiveData = effectiveResponse.data;

            // Fetch real effective data
            const effectiveRealResponse = await api.get(`/effective_real_total/${modelId}`);
            const effectiveRealData = effectiveRealResponse.data;

            setEffectiveData(effectiveData);
            setEffectiveRealData(effectiveRealData);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="ml-7 mt-5">
            <div className="flex items-center justify-between">
                <h2 className="text-2xl font-semibold">Suivi d'Effectif</h2>
                <div className="ml-7 mb-4 pr-6">
                    <select
                        className="block w-full mt-4 outline-0 p-[.5rem] border border-[#b3b3b3] focus:border-2 focus:border-[#2684ff] rounded"
                        value={selectedChain}
                        onChange={handleSelectChange}
                    >
                        <option value="">Select a Chain</option>
                        {chains.map((chain) => (
                            <option key={chain.id} value={chain.id}>
                                {chain.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            {model && (
                <div className="mt-4">
                    <p><strong>Modèle:</strong> {model.modele}</p>
                    <p><strong>Catégorie:</strong> {model.category}</p>
                    <p><strong>Photo:</strong>
                        <img
                            src={`http://crosscheckconf.net:8000${model.image}`}
                            alt={model.name}
                            className="h-[50px] w-[50px]"
                        />
                    </p>
                </div>
            )}

            {model && effectiveData && effectiveRealData && (
                <div className="overflow-x-auto mt-5">
                    <table className="table-auto w-full bg-slate-200 border-collapse border border-gray-300 shadow-md">
                        <thead className="bg-gray-100">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Date</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Jour</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Effectif Réel direct</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Effectif Prévisionnel direct</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Ecart Direct</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Effectif Réel indirect</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Effectif Prévisionnel indirect</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Ecart Indirect</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(effectiveData).map((date, index) => {
                                const realDirect = effectiveRealData[date]?.effectifDirects || 0;
                                const plannedDirect = effectiveData[date]?.effectifDirects || 0;
                                const realIndirect = effectiveRealData[date]?.effectifIndirects || 0;
                                const plannedIndirect = effectiveData[date]?.effectifIndirects || 0;

                                const directEcart = realDirect - plannedDirect;
                                const indirectEcart = realIndirect - plannedIndirect;

                                return (
                                    <tr key={date}>
                                        <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">{date}</td>
                                        <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">{new Date(date).toLocaleDateString('fr-FR', { weekday: 'long' })}</td>
                                        <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">{realDirect}</td>
                                        <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">{plannedDirect}</td>
                                        <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">{directEcart}</td>
                                        <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">{realIndirect}</td>
                                        <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">{plannedIndirect}</td>
                                        <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">{indirectEcart}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default SuivrDeEffect;