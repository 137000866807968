import React, { useState , useEffect } from 'react'
import api from "../../api/axios";

const PricePerMinute = () => {
  const [selectedModel, setSelectedModel] = useState("");
  const [totalChains, setTotalChains] = useState(0);
  const [error, setError] = useState(null);
  const [couteTransport, setCouteTransport] = useState(0);
  const [effectifInDirect, setEffectifInDirect] = useState(0);
  const [systemConstant, setSystemConstant] = useState({
    Nombre_d_heures_par_jour: "",
    Taux_horaire_SMIG_16_29: "",
    Taux_horaire_17_00: "",
    Taux_horaire_17_50: "",
    Masse_salariale_16_29: "",
    Masse_salariale_17_00: "",
    Masse_salariale_17_50: "",
    Capacité_par_unité_transport: "",
    cotisation_entroprise_trans: "",
    Coût_par_trajet: "",
    Coût_énergie_journalier: "",
    Coût_charges_fixes_journalier: "",
  });
  const [totalHours, setTotalHours] = useState(0);
  const [effectifDirect, setEffectifDirect] = useState(0);
  const [models, setModels] = useState([]);
  const [totalMassSalary, setTotalMassSalary] = useState(0);
  const [fils, setFils] = useState(0);
  const [plastique, setPlastique] = useState(0);
  const [coûtEnergie, setCoûtEnergie] = useState(0);
  const [coûtChargesFixes, setCoûtChargesFixes] = useState(0);
  const [coûtDeRevient, setCoûtDeRevient] = useState(0);
  const [totalExport, setTotalExport] = useState(0);
  const [temp, setTemp] = useState(0);
  const [coutMin , setCoutmin] = useState(0);
  const [totalTemp, setTotalTemp] = useState(0);

  const getTempAlloue = async () => {
    if (selectedModel) {
      try {
        const response = await api.get(`/temps-alloue/${selectedModel}`);
        const tempAlloue = response.data.temps_alloue; // Access the nested object
        setTotalTemp(tempAlloue.temps_alloue); // Set the specific value
      } catch (error) {
        console.error('Error fetching temps_alloue:', error);
      }
    }
  };
  
  const getExport = async () => {
    if(selectedModel) {
      const response = await api.get(`/models/${selectedModel}`);
      const exportData = response.data;
      setTotalExport(exportData.qte_societe);
    }
  }

  useEffect(() => {
    getExport()
    getTempAlloue()
  }, [selectedModel]);

  useEffect(() => {
    const min_presence = totalHours * effectifDirect * 60;
    const min_produit = totalExport * totalTemp; 
    if (min_presence > 0) {
      const Rendement = min_produit / min_presence;
      setTemp(effectifDirect * totalHours * 60 * Rendement);
    }
  }, [effectifDirect, totalHours, totalTemp]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const modelsResponse = await api.get("/models");
        setModels(modelsResponse.data);

        const systemResponse = await api.get("/system_constants_latest");
        if (systemResponse.data) {
          const { id, created_at, updated_at, effectif_fix, ...filteredData } =
            systemResponse.data;
          setSystemConstant({
            ...filteredData,
          });
        }

        const responseIndirect = await api.get(`/effective_indirect_total`);
        const responseChains = await api.get(`/total_chain`);
        if(selectedModel) {
          const responseDirect = await api.get(
            `/effective_direct_standard/${selectedModel}`
          );
          setEffectifDirect(parseInt(responseDirect.data.total_effectif_direct));
        }
        setEffectifInDirect(
          parseInt(responseIndirect.data.total_effectif_indirect)
        );
        setTotalChains(parseInt(responseChains.data.total_chains));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedModel]);

  const calculateCouteTransport = () => {
    const QP_effectif = effectifInDirect / totalChains;
  
    const {
      Nombre_d_heures_par_jour,
      Capacité_par_unité_transport,
      Coût_par_trajet,
      cotisation_entroprise_trans,
    } = systemConstant;
  
    
    const Nombre_de_jours_de_production = totalHours / Nombre_d_heures_par_jour;
  
    const Refacturation_transport_MOD_indirecte = -(
      (cotisation_entroprise_trans *
        Nombre_de_jours_de_production *
        QP_effectif) /
      26
    );
  
    const Refacturation_transport_MOD_directe = -(
      (cotisation_entroprise_trans *
        Nombre_de_jours_de_production *
        effectifDirect) /
      26
    );
  
    const Nombre_unite_utilise =
      (QP_effectif + effectifDirect) / Capacité_par_unité_transport;
  
    const transportCost =
      2 *
        Nombre_unite_utilise *
        Nombre_de_jours_de_production *
        Coût_par_trajet +
      (Refacturation_transport_MOD_directe +
        Refacturation_transport_MOD_indirecte);
  
    setCouteTransport(transportCost.toFixed(4));
  };
  
  const calculateTotalMasseSalariale = () => {
    const {
      Taux_horaire_SMIG_16_29,
      Taux_horaire_17_00,
      Taux_horaire_17_50,
      Masse_salariale_16_29,
      Masse_salariale_17_00,
      Masse_salariale_17_50,
    } = systemConstant;
    
  
    const congeAnual =
      (totalHours * effectifDirect * Taux_horaire_SMIG_16_29 * 1.5) / 26;
  
    const masseSalariale1629 =
      totalHours *
      effectifDirect *
      Masse_salariale_16_29 *
      0.01 *
      Taux_horaire_SMIG_16_29;
  
    const masseSalariale1700 =
      totalHours *
      effectifDirect *
      Masse_salariale_17_00 *
      0.01 *
      Taux_horaire_17_00;
  
    const masseSalariale1750 =
      totalHours *
      effectifDirect *
      Masse_salariale_17_50 *
      0.01 *
      Taux_horaire_17_50;
  
    const QP_effectif = effectifInDirect / totalChains;
  
    const congeAnualIndirect =
      (totalHours * QP_effectif * Taux_horaire_SMIG_16_29 * 1.5) / 26;
  
    const masseSalarialeIndirect1629 =
      totalHours *
      QP_effectif *
      Masse_salariale_16_29 *
      0.01 *
      Taux_horaire_SMIG_16_29;
  
    const masseSalarialeIndirect1700 =
      totalHours *
      QP_effectif *
      Masse_salariale_17_00 *
      0.01 *
      Taux_horaire_17_00;
  
    const masseSalarialeIndirect1750 =
      totalHours *
      QP_effectif *
      Masse_salariale_17_50 *
      0.01 *
      Taux_horaire_17_50;
  
    const masseSalarialeDirecte =
      masseSalariale1629 + masseSalariale1700 + masseSalariale1750 + congeAnual;
  
    const masseSalarialeInDirecte =
      masseSalarialeIndirect1700 +
      masseSalarialeIndirect1629 +
      masseSalarialeIndirect1750 +
      congeAnualIndirect;
  
    setTotalMassSalary(masseSalarialeInDirecte + masseSalarialeDirecte);
  };
  

  const calculateCouteFilAndPlastique = () => {
    setFils(totalExport * (20000 / 18602));
    setPlastique(totalExport * (15000 / 18602));
  };

  const calculateCoûtEnergie = () => {
    const { Coût_énergie_journalier, Nombre_d_heures_par_jour } =
      systemConstant;
    const Nombre_de_jours_de_production = totalHours / Nombre_d_heures_par_jour;
    setCoûtEnergie(Nombre_de_jours_de_production * Coût_énergie_journalier);
  };

  const calculateCoûtChargesFixes = () => {
    const { Coût_charges_fixes_journalier, Nombre_d_heures_par_jour } =
      systemConstant;
    const Nombre_de_jours_de_production = totalHours / Nombre_d_heures_par_jour;
    setCoûtChargesFixes(
      Nombre_de_jours_de_production * Coût_charges_fixes_journalier
    );
  };

  const calculateCoûtDeRevient = () => {
    const totalCost =
      parseFloat(fils) +
      parseFloat(plastique) +
      parseFloat(totalMassSalary) +
      parseFloat(couteTransport) +
      parseFloat(coûtChargesFixes) +
      parseFloat(coûtEnergie);

    setCoûtDeRevient(totalCost.toFixed(2));
  };

  useEffect(() => {
    if (
      totalHours > 0 &&
      effectifDirect > 0 &&
      effectifInDirect > 0 &&
      totalChains > 0 &&
      systemConstant.Nombre_d_heures_par_jour
    ) {
      calculateCouteTransport();
      calculateTotalMasseSalariale();
      calculateCouteFilAndPlastique();
      calculateCoûtEnergie();
      calculateCoûtChargesFixes();
    }
  }, [
    totalHours,
    effectifDirect,
    effectifInDirect,
    totalChains,
    totalExport,
    systemConstant,
  ]);

  useEffect(() => {
    calculateCoûtDeRevient()
  }, [coûtEnergie,coûtChargesFixes, fils, plastique,totalMassSalary , couteTransport ])

  const fetchWorkedHours = async (modelId) => {
    try {
      const response = await api.get(`/product_plans_worked_hours/${modelId}`);
      setTotalHours(response.data.worked_hours_count);
    } catch (error) {
      console.error("Error fetching worked hours:", error);
      setError(error.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    if (selectedModel) {
      fetchWorkedHours(selectedModel);
    }
  }, [selectedModel]);

  const handleSelectChange = (e) => {
    setSelectedModel(e.target.value);
  };

  useEffect(() => {
    setCoutmin(coûtDeRevient / temp)
  }, [temp , coûtDeRevient])

  return (
    <div className="ml-7 mt-5">
      <div className="flex items-center justify-between">
            <h2 className="text-2xl font-semibold">Le coût minute</h2>
            <div className="ml-7 mb-4 pr-6">
              <select
                className="block w-full mt-4 outline-0 p-[.5rem] border border-[#b3b3b3] focus:border-2 focus:border-[#2684ff] rounded"
                value={selectedModel}
                onChange={handleSelectChange}
              >
                <option value="">Select model</option>
                {models.map((model) => (
                  <option key={model.id} value={model.id}>
                    {model.modele}
                  </option>
                ))}
              </select>
            </div>
      </div>
      <div className="w-full mr-5">
        {error ? (
          <div className="text-red-500 ml-7">{error}</div>
        ) : (
          coutMin.toFixed(2) + ' DH / min'
        )}
      </div>
    </div>
  )
}

export default PricePerMinute
