import React, { useEffect, useState } from "react";
import api from "../../api/axios";

const SuivrDeProd = () => {
    const [chains, setChains] = useState([]);
    const [selectedChain, setSelectedChain] = useState("");
    const [error, setError] = useState("");
    const [model, setModel] = useState(null);
    const [planningData, setPlanningData] = useState({});
    const [productionData, setProductionData] = useState({});

    useEffect(() => {
        const fetchChains = async () => {
            try {
                const response = await api.get("/chains");
                setChains(response.data);
            } catch (error) {
                console.error("Error fetching chains:", error);
            }
        };

        fetchChains();
    }, []);

    useEffect(() => {
        const fetchModelByChain = async () => {
            if (selectedChain) {
                try {
                    const response = await api.get(`/getmodelsByChain/${selectedChain}`);
                    fetchPlanningData(response.data.id);
                    setModel(response.data); 
                } catch (error) {
                    console.error("Error fetching model by chain:", error);
                    setModel(null); // Reset model on error
                }
            }
        };

        fetchModelByChain();
    }, [selectedChain]);

    const handleSelectChange = (e) => {
        setSelectedChain(e.target.value);
        setModel(null); // Reset model on chain change
        setPlanningData({});
        setProductionData({});
    };

    const fetchPlanningData = async (modelId) => {
        setError(null);
        try {
            const planningResponse = await api.get(`/product_plans_model_dash/${modelId}`);
            const productionResponse = await api.get(`/export_total/${modelId}`);

            setPlanningData(planningResponse.data.planning || {});
            setProductionData(productionResponse.data.planning || {});
        } catch (error) {
            console.error(error);
            setError(error.response?.data?.message || "An error occurred");
        }
    };

    return (
        <div className="ml-7 mt-5">
            <div className="flex items-center justify-between">
                <h2 className="text-2xl font-semibold">Suivi de production</h2>
                <div className="ml-7 mb-4 pr-6">
                    <select
                        className="block w-full mt-4 outline-0 p-[.5rem] border border-[#b3b3b3] focus:border-2 focus:border-[#2684ff] rounded"
                        value={selectedChain}
                        onChange={handleSelectChange}
                    >
                        <option value="">Select a Chain</option>
                        {chains.map((chain) => (
                            <option key={chain.id} value={chain.id}>
                                {chain.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {model && (
                <div className="mt-4">
                    <p><strong>Modèle:</strong> {model.modele}</p>
                    <p><strong>Catégorie:</strong> {model.category}</p>
                    <p><strong>Photo:</strong>
                        <img
                            src={`http://crosscheckconf.net:8000${model.image}`}
                            alt={model.name}
                            className="h-[50px] w-[50px]"
                        />
                    </p>
                </div>
            )}
            {model && (
                <div className="overflow-x-auto mt-5">
                    <table className="table-auto w-full bg-slate-200 border-collapse border border-gray-300 shadow-md">
                        <thead className="bg-gray-100">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Date de production</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Jour</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Production Réelle</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Production prévisionnelle</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Cumul réél</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Cumul prévsionnel</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Ecart</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Nombre d'heures travaillés Réel</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-[#4E4A4A] uppercase tracking-wider border border-gray-300">Nombre d'heures travaillés Prévisionnel</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(planningData).map((date, index) => {
                                const realProduction = Number(productionData[date] || 0);
                                const plannedProduction = Number(planningData[date] || 0);

                                const cumulReal = Object.keys(productionData)
                                    .slice(0, index + 1)
                                    .reduce((sum, key) => sum + Number(productionData[key] || 0), 0);

                                const cumulPlanned = Object.keys(planningData)
                                    .slice(0, index + 1)
                                    .reduce((sum, key) => sum + Number(planningData[key] || 0), 0);

                                const ecart =  cumulReal - cumulPlanned;

                                return (
                                    <tr key={date}>
                                        <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">{date}</td>
                                        <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">{new Date(date).toLocaleDateString('fr-FR', { weekday: 'long' })}</td>
                                        <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">{realProduction}</td>
                                        <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">{plannedProduction}</td>
                                        <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">{cumulReal}</td>
                                        <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">{cumulPlanned}</td>
                                        <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">{ecart}</td>
                                        <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">--</td>
                                        <td className="p-[2rem] text-left text-[#4E4A4A] font-semibold border border-gray-300">--</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default SuivrDeProd;
